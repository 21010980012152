/* Copyright (C) 2019 Ian Shelanskey - All Rights Reserved
 */

import { useState, useEffect, useRef } from "react";
import moment from "moment";

let socket = null;

let SendInterval = null;
let SyncTimeout;

const subscriptions = {
  info: [],
  ShowData: [],
  GameData: [],
  audioUpdate: [],
  ping: [],
  showStatus: [],
  requestScore: [],
  leaderboard: [],
  rank: [],
};

/** handles messages sent from the Sync Core service.
 *  @param {any} ev - message from Sync Core
 */
function handleMessage(ev) {
  // Convert message from SyncCore to JSON
  const data = JSON.parse(ev.data);

  // If its valid
  if ("MessageType" in data) {
    // If we recv a SyncRes for server and we havent completed sync
    if (data.MessageType === "SyncRes" && !SyncAck) {
      // Do NTP time offset.
      findOffset(
        data.ServerTime,
        data.ClientTime,
        Math.floor(performance.now() * MS_IN_NS)
      );

      // If we have sync
      if (PercentSynced === 1) {
        clearInterval(SendInterval);
        // Acknowledge the sync status to server.
        doSyncAck();
        // Set Guard for conditional
        SyncAck = true;
      } else {
        doSyncReq();
      }
    }
    // See if the show has started..
    if (data.MessageType === "GameData") {
      Playing = data.Playing;
      StartedAt = data.StartedAt;
    }
    return data;
  }
  return null;
}

function doSyncReq() {
  socket.send(
    JSON.stringify({
      MessageType: "SyncReq",
      ClientTime: Math.floor(performance.now() * MS_IN_NS),
    })
  );
}

function doSyncAck() {
  socket.send(
    JSON.stringify({
      MessageType: "SyncAck",
      FullySynced: true,
    })
  );
}

/** Calculates the preroll that the game should be tracking too.
 *  @returns {number} Preroll time since show started.
 */
function CalcPreRoll() {
  const prerollNS =
    performance.now() * MS_IN_NS - (StartedAt - TimeOffset * MS_IN_NS);
  return prerollNS / 1000000000;
}

/** Opens Websocket connection and Starts Sync Process
 * @param {String} addr Websocket Address
 */
export function SyncStart(addr) {
  // guard for one socket connection
  if (socket) return;
  // open socket
  socket = new WebSocket(addr);

  // Connection opened
  socket.addEventListener("open", (ev) => {
    Connected = true;

    // start sync process.
    doSyncReq();

    // ! this is a mess - definitely needs love in the future.
    SyncTimeout = setTimeout(() => {
      SyncTimesUp();
    }, 10000);

    SendInterval = setInterval(() => {
      doSyncReq();
    }, 1000);
  });

  // Connection Error
  socket.addEventListener("error", (err) => {
    // eslint-disable-next-line no-console
    console.error(err);

    // close socket and set to null.
    socket.close();
    socket = null;
    // todo maybe try to open socket again?
  });

  // handle every message
  socket.addEventListener("message", (ev) => {
    const messageData = handleMessage(ev);
    // Guard against bad data
    if (!messageData) return;

    // If we have handlers for that message
    if (messageData.MessageType in subscriptions) {
      // get handlers
      const messageSubscription = subscriptions[messageData.MessageType];

      // send data to each handler
      updateSubscriptions(messageSubscription, messageData);
    }

    // update show status after message.
    const isSyncing = Connected && PercentSynced < 1;
    const isSyncd = SyncAck;
    const isPlaying = Playing;
    const offset = TimeOffset;
    const showStatus = { isSyncing, isSyncd, isPlaying, offset };

    // get show status handlers
    const showStatusSubscription = subscriptions.showStatus;

    // send data to show status handlers
    updateSubscriptions(showStatusSubscription, showStatus);
  });

  setInterval(() => {
    // update show status after message.
    const isSyncing = Connected && PercentSynced < 1;
    const isSyncd = SyncAck;
    const isPlaying = Playing;
    const offset = TimeOffset;
    const showStatus = { isSyncing, isSyncd, isPlaying, offset };

    // get show status handlers
    const showStatusSubscription = subscriptions.showStatus;

    // send data to show status handlers
    updateSubscriptions(showStatusSubscription, showStatus);
  }, 1000);
}

/** Update time that the game is tracking to
 * on a 10ms interval
 * @returns {number} tracking time
 */
export function usePreroll() {
  const [preroll, setPreroll] = useState(0);

  useEffect(() => {
    function handlePrerollUpdate(_preroll) {
      // makes a frame for time bucketing
      const frame = Math.floor((_preroll * 1000) / 10) * 10;
      setPreroll(frame);
    }

    // continually updates game time.
    setInterval(() => {
      if (PercentSynced === 1) {
        handlePrerollUpdate(CalcPreRoll());
      }
    }, 10);
  });
  return preroll;
}

export function useInternalPreroll(startPerformanceTime) {
  const [preroll, setPreroll] = useState(0.0);
  const [startTime, setStartTime] = useState(startPerformanceTime)

  useEffect(() => {
    function handlePrerollUpdate(_preroll) {
      // makes a frame for time bucketing
      const frame = Math.floor((_preroll ) / 10) * 10;
      setPreroll(frame);
    }

    // continually updates game time.
    setInterval(() => {
        handlePrerollUpdate((performance.now()-startTime));
    }, 10);
  });
  return preroll;
}

/** Updates show status */
export function useShowStatus() {
  const [subscribed, setSubscribed] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [synced, setSynced] = useState(false);
  const [playing, setIsPlaying] = useState(false);
  const [timeOffset, setOffset] = useState();


  function unsubscribe(handler) {
    unsubscribeFromUpdates("showStatus", handler);
  }

  function handleNewStatus({ isSyncing, isSyncd, isPlaying, offset }) {
    setSyncing(isSyncing);
    setSynced(isSyncd);
    setIsPlaying(isPlaying);
    setOffset(offset);

    // ! this is interesting.
    // If we are playing we don't get show status anymore?
    if (isPlaying) {
      unsubscribe(this);
    }
  }

  function subscribe() {
    if (subscribed) return;
    subscribeToUpdates("showStatus", handleNewStatus);
    setSubscribed(true);
  }

  return {
    isSyncing: syncing,
    isSyncd: synced,
    isPlaying: playing, // start show clicked
    timeOffset,
    subscribeToShowStatus: subscribe,
    unsubscribeFromShowStatus: unsubscribe,
  };
}

export function useSidecar() {
  const showData = useGameData();
  const [sidecar, setSidecar] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function handleNewSidecar(sidecarJson) {
      const { timings } = sidecarJson;
      setSidecar({ timings });
    }
    console.log(showData)
    if (showData.SidecarPath) {
      fetch(showData.SidecarPath)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          handleNewSidecar(data);
          setLoading(false);
        });
    }
  }, [showData]);
  return [sidecar, loading];
}

export function useGameData() {
  const [message, setMessage] = useState({});

  useEffect(() => {
    function handleNewMessage(_message) {
      console.log(_message)
      setMessage(_message.Data);
    }
    subscribeToUpdates("GameData", handleNewMessage);
  }, []);
  return message;
}

export function useNumberOfPlayers() {
  const [numPlayers, setNumPlayers] = useState(1);

  useEffect(() => {
    function handlePlayersMessage(message) {
      setNumPlayers(message.NumConnected);
    }

    subscribeToUpdates("GameData", handlePlayersMessage);
    subscribeToUpdates("ping", handlePlayersMessage);
  }, []);

  return numPlayers;
}

export function useLeaderboard() {
  const [_leaderboard, setLeaderboard] = useState([]);
  const leaderboard = useRef(_leaderboard);
  leaderboard.current = _leaderboard;

  function handleNewMessage(_message) {
    if (
      JSON.stringify(_message.Leaderboard) !==
      JSON.stringify(leaderboard.current)
    ) {
      setLeaderboard(_message.Leaderboard);
    }
  }

  useEffect(() => {
    subscribeToUpdates("leaderboard", handleNewMessage);
  }, []);

  return [leaderboard.current, setLeaderboard];
}

export function useNextGameTime(){
  const [nextGameTime, setNextGameTime] = useState(moment(0));

  useEffect(()=>{
    function handleNewGameData(message){
      if(message.Data.NextShow){
        setNextGameTime(moment.utc(message.Data.NextShow))
      }
    }
    subscribeToUpdates("GameData", handleNewGameData);
  }, [])
  return [nextGameTime]
}

export function useRank() {
  const [rank, setRank] = useState(null);

  useEffect(() => {
    function handleNewMessage(message) {
      setRank(message.Rank);
    }

    subscribeToUpdates("rank", handleNewMessage);
  }, []);
  return rank;
}

export function submitScore(score) {
  socket.send(JSON.stringify({ MessageType: "ScoreUpdate", Score: score }));
}

export function claimRank(initials, rank) {
  socket.send(
    JSON.stringify({
      MessageType: "ScoreClaim",
      Initials: initials,
      Rank: rank,
    })
  );
}

export function subscribeToUpdates(messageType, cb) {
  if (!socket) {
    // eslint-disable-next-line no-console
    console.error(`must open socket before subcribing to ${messageType}`);
  }
  subscriptions[messageType].push(cb);
}

export function unsubscribeFromUpdates(messageType, cb) {
  const cbidx = subscriptions[messageType].indexOf(cb);
  subscriptions[messageType].splice(cbidx, 1);
}

/** Interates through all handlers in subscription
 * and passes data.
 * @param {Array<Function>} sub Array of callbacks to pass data too.
 * @param {any} data the data to pass.
 */
function updateSubscriptions(sub, data) {
  sub.forEach((cb) => {
    if (typeof cb === "function") {
      try {
        cb(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  });
}

/* This should all probably live in another file.

  Below is sync algo stuff. This determines the time 
offset between client and server. 
*/

const MS_IN_NS = 1000000;
const ROUND_TRIP_THRESH_MS = 100;

let SyncAck = false;

let PercentSynced = 0.0;
let Playing = false;
let StartedAt = 0;
let Connected = false;

let TimeOffset = 10000;
let timeOffsetRange = [];
const timeOffsetRangeLength = 5;
const timeOffsetAverageRange = [];
const timeOffsetAverageRangeLength = 10;
const consistencyThresh = 10;
const best = { rtd: null, to: null, num: 0 };
const NumTries = 150;


function SyncTimesUp() {
  if (SyncAck && PercentSynced === 1) return;
  console.log("Times up to sync");
  if (best.num > 0) {
    TimeOffset = best.to;
  } else {
    TimeOffset = Date.now(); // this might need to be a different number...
  }
  SyncAck = true;
  PercentSynced = 1;
  doSyncAck()
}

function findOffset(serverTime, sentClientTime, recvclientTime) {
  const timeOffset =
    (serverTime - sentClientTime + (serverTime - recvclientTime)) / 2;
  const roundTripDelay = recvclientTime - sentClientTime;

  if (best.rtd) {
    if (best.rtd > roundTripDelay) {
      best.rtd = roundTripDelay;
      best.to = timeOffset / MS_IN_NS;
    }
  } else {
    best.rtd = roundTripDelay;
    best.to = timeOffset;
  }
  best.num += 1;
  if (best.num > NumTries) {
    TimeOffset = best.to;
    PercentSynced = 1;
  }

  if (roundTripDelay < ROUND_TRIP_THRESH_MS * MS_IN_NS) {
    TimeOffset = timeOffset / MS_IN_NS;
    PercentSynced = 1;
  } else {
    timeOffsetRange.push(timeOffset / MS_IN_NS);
    if (timeOffsetRange.length > timeOffsetRangeLength) {
      timeOffsetRange.shift();
      const timeOffsetAverage =
        timeOffsetRange.reduce((acc, nex) => acc + nex) / timeOffsetRangeLength;
      timeOffsetAverageRange.push(timeOffsetAverage);
      timeOffsetRange = [];
      if (timeOffsetAverageRange.length > timeOffsetAverageRangeLength) {
        timeOffsetAverageRange.shift();
        const trough = Math.min(...timeOffsetAverageRange);
        const peak = Math.max(...timeOffsetAverageRange);
        const diff = Math.abs(peak - trough);
        if (diff <= consistencyThresh) {
          TimeOffset =
            timeOffsetAverageRange.reduce((acc, nex) => acc + nex) /
            timeOffsetAverageRangeLength;
          PercentSynced = 1;
        }
      }
    }
  }
}
