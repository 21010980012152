import { useHistory, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import ResponsiveContainer from "~/components/responsiveContainer";
import styles from "./styles.module.css";
import SimonLoader from "~/components/simonLoader";
import SimonPill from "~/components/simonPill";
import SimonLogo from "~/components/simonLogo";
import PlayerContext from "~/utils/playSound";
import SimonEsb from "~/components/simonEsb";
import SimonScore from "~/components/simonScore";
import SimonLost from "~/components/simonLost";
import { submitScore, useInternalPreroll } from "~/components/syncComponent/syncAPI";
import { SCORE_PER_TAP, roundBonus } from "~/utils/score";
import { randomTimingsNoEnd } from "~/utils/tones.mjs";

const SimonBoardInfinite = () => {
  const player = useContext(PlayerContext);
  const history = useHistory();
  const location = useLocation();

  // Game states - controlled by timings from API
  // that we receive at start of game
  const [playing, setPlaying] = useState(false);
  const [watching, setWatching] = useState(true);
  const [waiting, setWaiting] = useState(true);
  const [countdown, setCountdown] = useState(3);
  const [round, setRound] = useState();
  const preroll = useInternalPreroll(performance.now()+2000);

  // Board states - manipulation of board lights and sounds
  const [sequence, setSequence] = useState([]);
  const [nextEvent, setNextEvent] = useState({});
  const eventRef = useRef(nextEvent);
  eventRef.current = nextEvent;
  const sequenceRef = useRef(sequence);
  sequenceRef.current = sequence;
  const [subSequence, setSubSequence] = useState([]);
  const [active, setActive] = useState(null);

  // Player states - controlled by user action
  const [score, setScore] = useState(0);
  const scoreRef = useRef(score);
  scoreRef.current = score;
  const [lost, setLost] = useState(false);
  const [wonRound, setWonRound] = useState(false);

  const eventHandler = (event) => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.info(event.debug);
    }

    if (event.GameOver) {
      //submitScore(scoreRef.current);
      history.replace("/finish", {
        score: scoreRef.current,
        practice: false,
        infinite: true
      });
      return;
    }

    setPlaying(event.Playing);
    setWatching(event.Watching);

    if (event.Watching) {
      setCountdown(false);
      setWonRound(false);
      setLost(false);
    }

    if (event.Playing) setWonRound(false);

    if (event.round) {
      setRound(event.round);
    }

    if (!event.Flash) {
      setActive(null);
      return;
    }

    if (event.Countdown) {
      setCountdown(event.Countdown);
      setWonRound(false);
      setLost(false);
      player.playSound(event.Flash, 1000);
      setActive(event.Flash);
      return;
    }

    player.playSound(event.Flash);
    setActive(event.Flash);
  };

  useEffect(() => {
    const [firstEvent, ...rest] = randomTimingsNoEnd(100);
    setNextEvent(firstEvent);
    setSequence(rest);
  }, []);

  // game round sequences
  useEffect(() => {
    if (!preroll) return;
    if (!nextEvent && !sequence.length) return;
    if (nextEvent?.time > preroll) return;

    if (nextEvent?.time < preroll) {
      // TODO(sb): should use isPlaying to determine if we can let you in late
      console.log("MISSED EVENT", preroll, nextEvent);
      let [next, ...rest] = sequence;
      while (next?.time <= preroll + 11) {
        [next, ...rest] = rest;
      }

      setNextEvent(next);
      setSequence(rest);
      return;
    }

    if (nextEvent?.time === preroll) {
      eventHandler(nextEvent);
    }

    const [next, ...rest] = sequence;
    if (!next) return;
    setSequence(rest);
    setNextEvent(next);
  }, [preroll, nextEvent, sequence]);

  // TODO(sb): not sure how to best handle this right now
  // Users who don't complete the sequence
  // lose when the "play time" for the round ends
  // useEffect(() => {
  //   if (countdown || watching || playing || wonRound) {
  //     return;
  //   }
  //   setLost(true);
  // }, [playing, wonRound, watching, countdown, waiting]);

  useEffect(() => {
    if (!round) return;
    const subSeq = [nextEvent, ...sequence].filter(
      (event) => event.round === round && event.Flash && !event.Countdown
    );

    setSubSequence(subSeq);
  }, [round]);

  const roundInfo = () => {
    if (playing) return null;
    if (lost) return "That hurts!";
    if (wonRound) return "Well done!";
    if (watching) return null;
    if (countdown || waiting) return "Is Coming Up";
    return null;
  };

  return (
    <>
      {lost && (
        <SimonLost setScore={() => setScore(score - roundBonus(round))} />
      )}
      <ResponsiveContainer>
        <SimonLogo />
        <SimonScore score={score} />

        <SimonEsb
          active={watching ? active : null}
          playing={playing}
          incrementScore={() => setScore(score + SCORE_PER_TAP)}
          sequence={subSequence}
          onLost={() => {
            setLost(true);
            setPlaying(false);
            player.playWrong();
            setNextEvent({})
            setSequence([])
            eventHandler({GameOver: true})
          }}
          onWonRound={() => {
            setPlaying(false);
            setWaiting(true);
            setWonRound(true);
          }}
        />

        <SimonPill numPlayers={130000} round={round} infinite roundInfo={roundInfo()} />
      </ResponsiveContainer>
      {countdown && (
        <SimonLoader
          key={round}
          active={active}
          allActive={false}
          classNames={{ root: styles.cover, donut: styles.donut }}
          copy={round ? null : "Get ready!"}
          copyAt={round ? null : 3}
          countdown={countdown}
          fadeCoverAt={round ? 3 : 5}
          fadeDonutAt={2}
        />
      )}
    </>
  );
};

SimonBoardInfinite.whyDidYouRender = {
  logOnDifferentValues: false,
};

export default SimonBoardInfinite;
