import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import zIndices from "~/utils/zIndices";
import { ReactComponent as Logo } from "~/resources/simon-logo.svg";

const SimonLogo = () => (
  <Link
    to={{
      pathname: "/lobby",
      state: { skipIntro: true },
    }}
  >
    <Logo className={styles.simonLogo} style={{ zIndex: zIndices.BRAND }} />
  </Link>
);

export default SimonLogo;
