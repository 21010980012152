import PropTypes from "prop-types";
import React, { useContext, useState, useEffect, useRef } from "react";
import classnames from "classnames";
import styles from "./styles.module.css";
import PlayerContext from "~/utils/playSound";
import zIndices from "~/utils/zIndices";
import litRed from "~/resources/esb/redLit.png";
import litGreen from "~/resources/esb/greenLit.png";
import litBlue from "~/resources/esb/blueLit.png";
import litYellow from "~/resources/esb/yellowLit.png";

const litImages = [litRed, litGreen, litBlue, litYellow];

const BuildingSlice = ({
  active,
  litSrc,
  loader,
  playing,
  keyed,
  onClick,
  segment,
}) => {
  const [tapped, setTapped] = useState(active);

  const flash = () => {
    if (!playing) return;
    setTapped(true);
    setTimeout(() => setTapped(false), 200);
    onClick();
  };

  const onTap = () => {
    flash();
  };

  useEffect(() => {
    if (keyed) flash();
  }, [keyed]);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        aria-label={`building segment ${segment}`}
        role="button"
        tabIndex={segment}
        onClick={onTap}
        className={styles.tapArea}
      />
      <img
        src={litSrc}
        className={classnames(styles.unlit, {
          [styles.active]: active,
          [styles.tapped]: tapped,
          [styles.loader]: loader,
        })}
        alt=""
      />
    </>
  );
};

BuildingSlice.propTypes = {
  active: PropTypes.bool.isRequired,
  segment: PropTypes.number.isRequired,
  keyed: PropTypes.bool.isRequired,
  litSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
};

const ESBBoard = ({
  active,
  loader,
  playing,
  sequence,
  incrementScore,
  onLost,
  onWonRound,
}) => {
  const [innerSequence, setInnerSequence] = useState(sequence);
  const player = useContext(PlayerContext);
  const [keyed, setKeyed] = useState(false);

  useEffect(() => {
    setInnerSequence(sequence);
  }, [sequence]);

  const keyboardRef = useRef(null);

  useEffect(() => {
    if (playing) {
      keyboardRef.current.focus();
    }
  }, [playing]);

  const onClick = (number) => {
    if (!playing) return;

    const [currentTone, ...restOfSequence] = innerSequence;

    if (number === currentTone.Flash) {
      player.playSound(number);
      incrementScore();
      setInnerSequence(restOfSequence);

      if (restOfSequence.length === 0) {
        onWonRound();
      }
    } else {
      onLost();
    }
  };

  // a11y - the slices themselves have tabIndexes and
  // aria labels that should help a screen reader user
  // play the game with keyboard number keys
  const handleKeys = (event) => {
    if (["1", "2", "3", "4"].includes(event.key)) {
      const key = parseInt(event.key, 10);
      setKeyed(key);
      setTimeout(() => setKeyed(false), 300);
    }
  };

  return (
    <div
      className={styles.buildingContainer}
      style={{ zIndex: zIndices.BOARD }}
    >
      <div
        ref={keyboardRef}
        tabIndex={0}
        role="button"
        className={styles.buttonContainer}
        onKeyDown={handleKeys}
      >
        {[1, 2, 3, 4].map((segment) => (
          <BuildingSlice
            active={active === segment}
            key={segment}
            keyed={segment === keyed}
            loader={loader}
            litSrc={litImages[segment - 1]}
            playing={playing}
            onClick={() => onClick(segment)}
            segment={segment}
          />
        ))}
      </div>
    </div>
  );
};

ESBBoard.propTypes = {
  active: PropTypes.number,
  playing: PropTypes.bool.isRequired,
  sequence: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  incrementScore: PropTypes.func,
  onLost: PropTypes.func,
  onWonRound: PropTypes.func,
  loader: PropTypes.bool,
};

/*  eslint-disable no-console */
ESBBoard.defaultProps = {
  active: null,
  loader: false,
  incrementScore: () => console.error("unhandled scoring"),
  onWonRound: () => console.error("unhandled win"),
  onLost: () => console.error("unhandled loss"),
};
/*  eslint-enable no-console */

export default ESBBoard;
