/* eslint-disable react/button-has-type */
import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import styles from "./styles.module.css";

const Button = ({
  children,
  className,
  disabled,
  onClick,
  size,
  type,
  variant,
}) => (
  <button
    className={classnames(
      styles.reset,
      className,
      styles.root,
      styles[variant],
      styles[size]
    )}
    disabled={disabled}
    onClick={onClick}
    type={type}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: null,
  disabled: false,
  onClick: null,
  size: "normal",
  type: "button",
  variant: "primary",
};

export default Button;
