import PropTypes from "prop-types";
import React from "react";
import { isMobile, isMobileSafari } from "react-device-detect";
import styles from "./styles.module.css";

import Twitter from "~/resources/twitter.svg";
import Facebook from "~/resources/facebook.svg";
import AndroidShare from "~/resources/share-android.svg";
import IosShare from "~/resources/share-ios.svg";

const webShare = () => {
  if (navigator.share) {
    navigator
      .share({
        title: "#SimonLive",
        text: "Join me to play #SimonLive on the Empire State Building",
        url: process.env.PUBLIC_URL,
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch(console.error);
  } else {
    // fallback
  }
};

const SocialButtons = ({ hashtag }) => (
  <div className={styles.root}>
    {hashtag && (
      <div className={styles.shareCTA}>
        <span className={styles.hashtag}>#SimonLive</span> • Tell your friends
      </div>
    )}
    <div className={styles.icons}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/intent/tweet?text=Join%20me%20to%20play%20%23SimonLive%20on%20the%20%40EmpireStateBldg%21"
      >
        <img alt="Twitter icon" src={Twitter} />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={
          "https://www.facebook.com/dialog/share?" +
          "app_id=251244302843301" + 
          "&display=popup" +
          "&hashtag=%23SimonLive"+
          "&quote=Join+me+to+play+%23SimonLive+on+the+Empire+State+Building"+
          "&href=https%3A%2F%2Fesbsimon.spark-sync.com%2F" +
          "&redirect_uri=https%3A%2F%2Fesbsimon.spark-sync.com%2F?"
        }
      >
        <img alt="facebook" src={Facebook} />
      </a>
      {/* NB: Navigator API can only be used on https or localhost */}
      {isMobile && (
        <button type="button" onClick={webShare}>
          <img alt="share" src={isMobileSafari ? IosShare : AndroidShare} />
        </button>
      )}
    </div>
  </div>
);

SocialButtons.propTypes = {
  hashtag: PropTypes.bool,
};

SocialButtons.defaultProps = {
  hashtag: false,
};

export default SocialButtons;
