import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import zIndices from "~/utils/zIndices";
import NumberOfPlayersContext from "~/utils/numPlayers";

const Pill = ({ loading = true, practice, infinite,round, roundInfo }) => {
  let numPlayers = 1;
  if (!practice && !infinite) {
    numPlayers = useContext(NumberOfPlayersContext);
  }

  return (
    <div className={styles.root} style={{ zIndex: zIndices.PILL }}>
      <div className={styles.roundIndicator}>
        Round {round === 0 ? 1 : round}
      </div>
      <div className={styles.playerContainer}>
        {roundInfo ? (
          <span className={styles.playerCount}>{roundInfo}</span>
        ) : ( infinite?(
          <>
          <span className={styles.playerCount}>
                Infinite Mode
            </span>
            </>
        ):(
            <>
              <span className={styles.playerCount}>
                {numPlayers} players
            </span>
              <span className={styles.playerCopy}>
                {loading ? "are in the game" : "have made no mistakes"}
              </span>
            </>
          ))}
      </div>
    </div>
  );
};

Pill.propTypes = {
  loading: PropTypes.bool,
  practice: PropTypes.bool,
  round: PropTypes.number,
  roundInfo: PropTypes.string,
};

Pill.defaultProps = {
  loading: true,
  practice: false,
  infinite: false,
  round: 1,
  roundInfo: undefined,
};

export default Pill;
