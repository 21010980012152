import React, { useState } from "react";
import Thumbsup from "~/resources/thumb_up-white-24dp.svg";
import Thumbsdown from "~/resources/thumb_down-white-24dp.svg";
import "./styles.css";
import { postRating } from "../../utils/syncClient";

export const Rating = ({title})=>{
	const [ratingGiven, setRatingGiven] = useState(false)
	const doRating = (isThumbUp)=>{
		setRatingGiven(true)
		postRating({rating:isThumbUp})
	}
	return(
		<div className="ratingBox">
			{ratingGiven? (
				<div>
					<div className="ratingTitle">Thank you!</div>
				</div>
			):(
			<div>
				<div className="ratingTitle">{title}</div>
				<div className="ratingContainer">
					<div className="ratingButton" onClick={()=>doRating(false)}><img alt="Thumbs down" src={Thumbsdown}></img></div>
					<div className="ratingButton" onClick={()=>doRating(true)}><img alt="Thumbs up" src={Thumbsup}></img></div>
				</div>
			</div>
			)}
		</div>
	)
}