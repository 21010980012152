import { createContext } from "react";
import { isMobile } from "react-device-detect";
import { Howl, Howler } from "howler";
import unmute from "./unmute";

const TONE_PATH = "https://d1thuhe8fny074.cloudfront.net/tones/tones-";
const SILENCE = "https://d1thuhe8fny074.cloudfront.net/silences.mp3";
const WRONG = "https://d1thuhe8fny074.cloudfront.net/tones/wrong-01.mp3";
class SoundPlayer {
  #lastAudio = null;

  #tones = {};

  constructor() {
    Howler.autoUnlock = true;
    Howler.usingWebAudio = true;

    this.#tones = [0, 1, 2, 3, 4].reduce((sounds, soundNumber) => {
      const sound = new Howl({
        src: [`${TONE_PATH}0${soundNumber}a.mp3`],
        autoplay: false,
        loop: false,
        html5: false,
        preload: true,
        onloaderror(err) {
          console.error(err);
        },
        onplayerror() {
          // eslint-disable-next-line no-console
          console.error("audio prevented");
        },
      });
      return {
        ...sounds,
        [soundNumber]: sound,
      };
    });

    this.#tones[5] = new Howl({
      src: [WRONG],
      autoplay: false,
      html5: false,
      loop: false,
      preload: true,
      onplayerror() {
        // eslint-disable-next-line no-console
        console.error("audio prevented");
      },
    });

    // NB(sb): when using WebAudio (which seems a lot more performant),
    // iOS treats the audio as being on the "ringer" level, and thus
    // wont play sound if the ringer switch is muted. This passes the
    // audio context to a function that allows us to play sounds with
    // audio API even when muted
    //
    // unmute function taken from https://github.com/swevans/unmute
    isMobile && unmute(Howler.ctx);
  }

  playSound = (soundNumber, duration = 400) => {
    const audio = this.#tones[soundNumber];
    audio.seek(0.0);
    const sound = audio.play();
    audio.fade(0, 1, 10, sound); // fade in
    audio.fade(1, 0, duration, sound); // fade out

    audio.on(
      "fade",
      () => {
        if (audio.seek() < 1) return; // ignore fade in
        audio.stop(sound); // stop sound after fade out
      },
      sound
    );
  };

  playSilence = (duration = 400) => {
    this.playSound(0, duration);
  };

  playWrong = () => {
    this.playSound(5, 1500);
  };
}

const PlayerContext = createContext(new SoundPlayer());
export default PlayerContext;
