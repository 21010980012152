import React, { memo, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { addDays } from "date-fns";

import styles from "./styles.module.css";
import PlayerContext from "~/utils/playSound";

import SimonCTA from "~/components/simonCta";
import SimonLoader from "~/components/simonLoader";
import SimonEsb from "~/components/simonEsb";
import { LOBBY_TIMINGS } from "~/utils";
import { useNextGameTime } from "../../components/syncComponent/syncAPI";

const UTC_HOUR_START = 2;
const PAUSE_LENGTH = 1000;
const TONE_LENGTH = 800;

const calcGameTime = (search) => {
  if (!search) {
    return addHours(startOfDay(new Date()), HOUR_START);
  }
  return fromUnixTime(search.substring(1));
};

const SimonLobby = () => {
  const player = useContext(PlayerContext);
  const location = useLocation();
  const { state: { skipIntro } = { skipIntro: false }, search } = location;
  const [allActive, setAllActive] = useState(!skipIntro);
  const [active, setActive] = useState(null);
  const [lobby, showLobby] = useState(skipIntro);
  const [nextGame] = useNextGameTime();

  useEffect(() => {
    if (skipIntro) return;

    const start = setTimeout(() => {
      setAllActive(false);
    }, PAUSE_LENGTH);
    const animateLobby = setTimeout(() => {
      showLobby(true);
      setActive(null);
    }, LOBBY_TIMINGS[5].time + TONE_LENGTH);

    const timeouts = LOBBY_TIMINGS.map((timingObject) =>
      setTimeout(() => {
        player.playSound(timingObject.Flash, TONE_LENGTH);
        setActive(timingObject.Flash);
      }, timingObject.time)
    );
    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(start);
      clearTimeout(animateLobby);
      timeouts.map((timeout) => clearTimeout(timeout));
    };
  }, []);

  if (!nextGame) return null;

  return (
    <div
      className={classnames(styles.container, { [styles.showLobby]: lobby })}
    >
      {!skipIntro && (
        <SimonLoader
          active={active}
          allActive={allActive}
          fadeCoverAt={3}
          fadeDonutAt={1}
          showCountdown={false}
        />
      )}
      <SimonEsb
        active={active}
        loader
        sequence={LOBBY_TIMINGS}
        playing={false}
      />
      <div className={styles.lobby}>
        <SimonCTA gameTime={nextGame} />
      </div>
    </div>
  );
};

SimonLobby.whyDidYouRender = {
  logOnDifferentValues: false,
};

export default memo(SimonLobby);
