import { useEffect, useState } from "react";
import fetchSyncCore from "./syncClient";

export default function useSync(syncTag) {
  const [loading, setLoading] = useState(true);

  const [syncRoute, setSyncRoute] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await fetchSyncCore(syncTag).then((response) => {
        if (response.success) {
          const {
            publicInfo: { link },
          } = response;

          setSyncRoute(link);
        }
      });

      setLoading(false);
    }
    fetchData();
  }, [syncTag]);

  return [syncRoute, loading];
}
