import PropTypes from "prop-types";
import React from "react";
import CountUp from "react-countup";
import zIndices from "~/utils/zIndices";
import styles from "./styles.module.css";

const SimonScore = ({ score }) => (
  <span style={{ zIndex: zIndices.SCORE }} className={styles.score}>
    <CountUp end={score} suffix=" Feet" duration={0.5} />
  </span>
);

SimonScore.propTypes = {
  score: PropTypes.number,
};

SimonScore.defaultProps = {
  score: 0,
};

export default SimonScore;
