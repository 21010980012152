import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.module.css";

const SimonHUD = ({ children, subtitle, title }) => (
  <div className={styles.root}>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{subtitle}</div>
    {children}
  </div>
);

SimonHUD.propTypes = {
  children: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.string,
};

SimonHUD.defaultProps = {
  children: null,
  subtitle: null,
  title: null,
};

export default SimonHUD;
