import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import zIndices from "~/utils/zIndices";
import styles from "./styles.module.css";
import Oops from "~/resources/oops.svg";

const SimonLost = ({ setScore }) => {
  const [messageAnimate, setMessageAnimate] = useState(false);

  useEffect(() => {
    const scoreTimeout = setTimeout(() => {
      setScore();
    }, 500);
    return () => clearTimeout(scoreTimeout);
  }, []);

  useEffect(() => setMessageAnimate(true), []);

  return (
    <div
      className={styles.root}
      style={{
        zIndex: zIndices.GAME_INTERSTITIAL,
      }}
    >
      <img
        src={Oops}
        className={classnames(styles.message, {
          [styles.animate]: messageAnimate,
        })}
        alt="oops"
      />
    </div>
  );
};

SimonLost.propTypes = {
  setScore: PropTypes.func.isRequired,
};

export default SimonLost;
