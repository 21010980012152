/* eslint-disable */
import './wdyr';
/* eslint-enable */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import App from "./app";

require("typeface-roboto");

// TODO(sb): where is screen supposed to come from?
// is the app rendered in a ssr template that includes this?
//
// update - screen is only available in PWA
//
// let screen;
// screen && screen?.lockOrientation("portrait");
// a

const Router =
  process.env.NODE_ENV === "development" ? BrowserRouter : MemoryRouter;

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
