import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import moment from 'moment'
import classnames from "classnames";
import { differenceInSeconds, formatDistanceStrict } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.css";
import SimonLogo from "~/resources/simon-logo.svg";
import SocialButtons from "~/components/socialButtons";
import {
  SyncStart,
  useShowStatus,
  useSidecar,
  useLeaderboard,
} from "~/components/syncComponent/syncAPI";
import { useNextGameTime } from "../../components/syncComponent/syncAPI";
import { customTimeFormat, useSync } from "~/utils";
import ButtonLinkRow from "~/components/simonButtonLinkRow";
import { duration } from "moment";

// GAME_BUFFER controls whether we see the countdown.
// If the next game starts within GAME_BUFFER seconds, then
// we see the timer and will be pushed into a game as soon as
// isPlaying returns true based on the websocket controlled
// showStatus hook. For testing purposes, increase this value
// to something aribitrarily large, like 86400 for 24 hours.
// For the activation, 60 seconds is what we designed for.

const GAME_BUFFER = 86400;

const syncTag = "e0e9fdba7e6bd0aa1b11";

const Timer = ({ startsAt }) => {
  let timeout;
  const [counter, setCounter] = useState( startsAt.diff(moment().local()) );

  useEffect(() => {
    timeout = setTimeout(() => setCounter(counter - 1), 1000);
    return () => clearTimeout(timeout);
  }, [counter]);

  const secondsUntilGame = Math.round(moment.duration(startsAt.diff(moment().local())).asSeconds());
  const gettingClose = secondsUntilGame > 59;
  const gameStartingLate = secondsUntilGame <=0;

  return (
    <span className={classnames(styles.countdown, styles.countdownLarge)}>
      {gettingClose? startsAt.fromNow(): gameStartingLate? "soon!": secondsUntilGame+' seconds'}
    </span>
  );
};

Timer.propTypes = {
  startsAt: PropTypes.instanceOf(moment.Moment).isRequired,
};

const Join = ({ startsAt, syncRoute }) => {
  useEffect(() => {
    SyncStart(syncRoute);
  }, []);
  const [{ timings }, sidecarLoading] = useSidecar();
  const {
    isSyncing,
    isSyncd,
    isPlaying,
    timeOffset,
    subscribeToShowStatus,
    unsubscribeFromShowStatus,
  } = useShowStatus();

  useEffect(() => {
    subscribeToShowStatus();

    return unsubscribeFromShowStatus;
  }, []);

  const history = useHistory();
  const [_leaderboard, setLeaderboard] = useLeaderboard();

  useEffect(() => {
    if (isPlaying && !sidecarLoading) {
      console.log('this should run')
      setLeaderboard([]);
      history.replace({
        pathname: "/play",
        state: { timings },
      });
    }
  }, [isPlaying, sidecarLoading]);

  return (
    <>
      {false && (
        <div className={styles.debug}>
          <div>
            <p>Status</p>
            <p>
              {isSyncing && "Syncing"}
              {isSyncd && "Synced"}
            </p>
          </div>
          <div>
            <p>Playing</p>
            <p>{isSyncing.toString()}</p>
          </div>
          <div>
            <p>Offset</p>
            <p>{timeOffset && timeOffset.toExponential(5)}</p>
          </div>
        </div>
      )}
      <div className={styles.counter}>
            <span>Next Game Begins</span>
            <span> </span>
            <Timer startsAt={startsAt} />
      </div>
    </>
  );
};

Join.whyDidYouRender = {
  logOnDifferentValues: false,
};

const Lobby = ({ startsAt }) => {
  return (
    <>
      <div className={styles.upcoming}>
        {startsAt.isAfter(moment())?(
          <div>
            <span>Next Live Game Begins</span>
            <span>{customTimeFormat(startsAt)}</span>
          </div>
        ):(
          <div>
            <span>The world's largest game of Simon!</span>
          </div>
        )}
      </div>
      <SocialButtons />
      <ButtonLinkRow
        links={[
          { to: "/leaderboard", text: "Leaderboard" },
          { to: "/infinite", text: "Infinite Play" },
        ]}
      />
    </>
  );
};


const SimonCTA = ({ gameTime }) => {
  const [syncRoute, showDataLoading] = useSync(syncTag);
  const [startingSoon, setStartingSoon] = useState(false);
  const [gameDate] = useNextGameTime();
  console.log(gameTime)
  useEffect(()=>{
    if (!syncRoute) return;
    SyncStart(syncRoute);
  }, [syncRoute]);


  useEffect(() => {
    const localDate = moment().local();
    const duration = moment.duration(gameDate.diff(localDate))
    const secondsUntilGame = duration.asSeconds();
    let changeLobby;

    if (Number.isNaN(secondsUntilGame) || secondsUntilGame < -160) {
      // missed today's game
      // eslint-disable-next-line no-console
      setStartingSoon(false);
    } else if (secondsUntilGame <= GAME_BUFFER) {
      changeLobby = setTimeout(() => {
        setStartingSoon(true);
      }, (secondsUntilGame - 180) * 1000);
    }

    // Set a timeout for when we should start the countdown

    return () => {
      changeLobby && clearTimeout(changeLobby);
    };
  }, [gameTime]);

  if (showDataLoading) return null;
  return (
    <div className={styles.root}>
      <div className={styles.brand}>
        <span className={styles.play}>Play</span>
        <img src={SimonLogo} alt="Simon logo" />
        <span>On The Empire State Building</span>
      </div>
      {startingSoon ? (
        <Join startsAt={gameDate} syncRoute={syncRoute} />
      ) : (
          <Lobby startsAt={gameDate} />
        )}
    </div>
  );
};


SimonCTA.whyDidYouRender = {
  logOnDifferentValues: false,
};

export default memo(SimonCTA);
