import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import ResponsiveContainer from "~/components/responsiveContainer";
import styles from "./styles.module.css";
import { randomTimings } from "~/utils/tones.mjs";
import SimonLoader from "~/components/simonLoader";
import SimonPill from "~/components/simonPill";
import SimonLogo from "~/components/simonLogo";
import PlayerContext from "~/utils/playSound";
import SimonEsb from "~/components/simonEsb";
import SimonScore from "~/components/simonScore";
import SimonLost from "~/components/simonLost";
import { SCORE_PER_TAP, roundBonus } from "~/utils/score";

const SimonBoardPractice = ({ rounds }) => {
  const player = useContext(PlayerContext);
  const history = useHistory();

  const [playing, setPlaying] = useState(false);
  const [watching, setWatching] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [round, setRound] = useState(0);
  const roundRef = useRef(round);
  roundRef.current = round;

  // Board states - manipulation of board lights and sounds
  const [sequence] = useState(randomTimings(rounds));
  const [subSequence, setSubSequence] = useState([]);
  const [active, setActive] = useState(null);

  const eventHandler = (event) => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.info(event.debug || "no debug");
    }

    if (event.GameOver) {
      history.replace("/finish", {
        score: scoreRef.current,
        practice: true,
        rounds,
      });
      return;
    }

    setPlaying(event.Playing);
    setWatching(event.Watching);

    if (event.Watching) {
      setCountdown(false);
      setWonRound(false);
      setLost(false);
    }

    if (event.Playing) setWonRound(false);

    if (event.round) {
      setRound(event.round);
    }

    if (!event.Flash) {
      setActive(null);
      return;
    }

    if (event.Countdown) {
      setCountdown(event.Countdown);
      setWonRound(false);
      setLost(false);
      player.playSound(event.Flash, 1000);
      setActive(event.Flash);
      return;
    }

    player.playSound(event.Flash);
    setActive(event.Flash);
  };

  useEffect(() => {
    if (!round) return;
    const subSeq = sequence.filter(
      (event) => event.round === round && event.Flash && !event.Countdown
    );
    console.log(subSeq)
    setSubSequence(subSeq);
  }, [round]);

  // Player states - controlled by user action
  const [score, setScore] = useState(0);
  const scoreRef = useRef(score);
  scoreRef.current = score;
  const [lost, setLost] = useState(false);
  const [wonRound, setWonRound] = useState(false);

  // game round sequences
  useEffect(() => {
    const timeouts = sequence.map((event) =>
      setTimeout(() => eventHandler(event), event.time)
    );
    return () => {
      timeouts.map((timeout) => clearTimeout(timeout));
    };
  }, []);

  // Users who don't complete the sequence
  // lose when the "play time" for the round ends
  useEffect(() => {
    if (countdown || watching || playing || wonRound) {
      return;
    }
    setLost(true);
  }, [playing, wonRound, watching, countdown, waiting]);

  const roundInfo = () => {
    if (playing) return "Practice Mode";
    if (lost) return "That hurts!";
    if (wonRound) return "Well done!";
    if (watching) return "Practice Mode";
    if (countdown || waiting) return "Is Coming Up";
    return "Practice Mode";
  };

  if (!sequence.length) return null;

  return (
    <>
      {lost && (
        <SimonLost
          setScore={() => setScore(score - roundBonus(round))}
          practice
        />
      )}
      <SimonLogo />
      <SimonScore score={score} />

      <ResponsiveContainer>
        <SimonEsb
          active={watching ? active : null}
          playing={playing}
          incrementScore={() => setScore(score + SCORE_PER_TAP)}
          sequence={subSequence}
          onLost={() => {
            player.playWrong();
            setLost(true);
            setPlaying(false);
          }}
          onWonRound={() => {
            setPlaying(false);
            setWaiting(true);
            setWonRound(true);
          }}
        />

        <SimonPill practice round={round} roundInfo={roundInfo()} />
      </ResponsiveContainer>
      {countdown && (
        <SimonLoader
          key={round}
          active={active}
          allActive={false}
          classNames={{ root: styles.cover, donut: styles.donut }}
          copy={round ? null : "Get ready!"}
          copyAt={round ? null : 3}
          countdown={countdown}
          fadeCoverAt={round ? 3 : 5}
          fadeDonutAt={2}
        />
      )}
    </>
  );
};

SimonBoardPractice.propTypes = {
  rounds: PropTypes.number.isRequired,
};

SimonBoardPractice.whyDidYouRender = {
  logOnDifferentValues: false,
};

export default SimonBoardPractice;
