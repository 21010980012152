import React, { createContext, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import SimonSplash from "~/views/simonSplash";
import SimonLobby from "~/views/simonLobby";
import SimonBoard from "~/views/simonBoard";
import SimonBoardPractice from "~/views/simonBoard/practice";
import SimonBoardInfinite from "~/views/simonBoard/infinite";
import SimonFinish from "~/views/simonFinish";
import SimonLeaderboard from "~/views/simonLeaderboard";
import { useNumberOfPlayers } from "~/components/syncComponent/syncAPI";

import SimonBoardDev from "~/views/simonBoardDev";
import NumberOfPlayersContext from "~/utils/numPlayers";
import SimonHowTo from "../views/simonHowTo";

const SimonApp = () => {
  const numberOfPlayers = useNumberOfPlayers(1);

  const location = useLocation();
  return (
    <main className="simon">
      <NumberOfPlayersContext.Provider value={numberOfPlayers}>
        <Switch location={location}>
          <Route exact path="/">
            <SimonSplash />
          </Route>
          <Route path="/lobby">
            <SimonLobby />
          </Route>
          <Route path="/practice" key={location.key}>
            <SimonBoardPractice rounds={5} />
          </Route>
          <Route path="/play">
            <SimonBoard />
          </Route>
          <Route path="/infinite" key={location.key}>
            <SimonBoardInfinite />
          </Route>
          <Route path="/finish" rank={1}>
            <SimonFinish />
          </Route>
          <Route path="/leaderboard">
            <SimonLeaderboard />
          </Route>
          <Route path="/dev_board">
            <SimonBoardDev />
          </Route>
          <Route path="/howto">
            <SimonHowTo />
          </Route>
        </Switch>
      </NumberOfPlayersContext.Provider>
    </main>
  );
};
export default SimonApp;
