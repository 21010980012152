import React from "react";
import { Link } from "react-router-dom";
import Button from "~/components/button";
import styles from "./styles.module.css";

export default () => (
  <div className={styles.main}>
    <div className={styles.rulesContainer}>
    <div className={styles.title}>
      <h1>
        How to Play:    
      </h1>
    </div>
    <div className={styles.rules}>Watch levels of the Empire State Building flash in a series of colors and tones. Tap the building on your device to correctly repeat the pattern. The longer you play, the more complex the sequences.</div>
    <br/>
    <div className={styles.rules}>For every correct answer, players climb further up the Empire State Building. Each wrong answer knocks you down. Try to make it to the top of the building, and the top of the leaderboard!</div>
    </div>
    <Link to="/lobby" replace>
      <Button variant="secondary" size="large">
        Got it! 
      </Button>
    </Link>

  </div>)