const baseTonesPerRound = 3;
const baseTiming = 530;
const playTiming = 530;
const slowPlayerBufferTiming = 1000;
const ADDED_TONES_PER_ROUND = 1;

const RED = 1;
const GREEN = 2;
const BLUE = 3;
const YELLOW = 4;

const TONES = [RED, GREEN, BLUE, YELLOW];

const LOBBY_TIMINGS = [RED, BLUE, GREEN, BLUE, YELLOW, GREEN].map(
  (tone, index) => ({
    Flash: tone,
    time: (1 + index) * 800 + 1000,
  })
);

const ROUND_LOAD_SEQUENCE = [
  {
    Flash: YELLOW,
    time: 1000,
  },
  {
    Flash: RED,
    time: 2000,
  },
  {
    Flash: GREEN,
    time: 3000,
  },
  {
    Flash: BLUE,
    time: 4000,
  },
  {
    Flash: YELLOW,
    time: 5000,
  },
];

// eslint-disable-next-line no-bitwise
const randomTone = () => TONES[(TONES.length * Math.random()) | 0];

const calcNewTimings = (timing, sequence, round) => {
  let newTiming = timing;
  return sequence.map((item, index) => {
    const newItem = {
      ...item,
      round,
      time: newTiming,
    };
    // needs to only add 400ms for off
    newTiming += newItem.Flash ? 400 : 130;

    return newItem;
  });
};



const randomSequence = (timing, count, round = 1, toneTiming = baseTiming) => {
  let sequenceTiming = timing;
  return Array.from({ length: count }).reduce((acc) => {
    sequenceTiming += toneTiming;
    return [
      ...acc,
      {
        Flash: randomTone(),
        Watching: true,
        Playing: false,
        time: sequenceTiming,
        round,
        debug: "=====================TONE FLASHED==========================",
      },
      {
        Flash: null,
        Watching: true,
        Playing: false,
        time: sequenceTiming + 400,
        round,
        debug: "=====================LIGHT OFF==========================",
      },
    ];
  }, []);
};





const randomTimings = (roundCount) => {
  let timingCursor;
  let toneMemo = [];

  return Array.from({ length: roundCount })
    .reduce(
      (timings, _, index) => {
        timingCursor = timingCursor || timings[timings.length - 1].time;
        timingCursor += baseTiming;
        const roundStart = roundStarter(timingCursor, index + 1);

        let sequence = toneMemo;

        if (!sequence.length) {
          sequence = randomSequence(timingCursor, baseTonesPerRound, index + 1);
          timingCursor = sequence[sequence.length - 1].time;
        } else {
          timingCursor += baseTiming;
          const updatedTimings = calcNewTimings(
            timingCursor,
            sequence,
            index + 1
          );
          timingCursor = updatedTimings[toneMemo.length - 2].time;

          sequence = [
            ...updatedTimings,
            ...randomSequence(timingCursor, ADDED_TONES_PER_ROUND, index + 1),
          ];
          timingCursor += baseTiming;
        }

        toneMemo = sequence;

        timingCursor += baseTiming;
        const playStart = playStarter(timingCursor, index + 1);

        timingCursor += playTiming * (sequence.length + 1);
        timingCursor += slowPlayerBufferTiming;

        const roundEnd = roundEnder(timingCursor, index + 1);

        return [...timings, roundStart, ...sequence, playStart, roundEnd];
      },
      [...gameStarter(0, 0)]
    )
    .concat([gameEnder(timingCursor + 100)]);
};




const randomTimingsNoEnd = (roundCount) => {
  let timingCursor;
  let toneMemo = [];

  return Array.from({ length: roundCount })
    .reduce(
      (timings, _, index) => {
        timingCursor = timingCursor || timings[timings.length - 1].time;
        timingCursor += baseTiming;
        const roundStart = roundStarter(timingCursor, index + 1);

        let sequence = toneMemo;

        if (!sequence.length) {
          sequence = randomSequence(timingCursor, baseTonesPerRound, index + 1);
          timingCursor = sequence[sequence.length - 1].time;
        } else {
          timingCursor += baseTiming;
          const updatedTimings = calcNewTimings(
            timingCursor,
            sequence,
            index + 1
          );
          timingCursor = updatedTimings[toneMemo.length - 2].time;

          sequence = [
            ...updatedTimings,
            ...randomSequence(timingCursor, ADDED_TONES_PER_ROUND, index + 1),
          ];
          timingCursor += baseTiming;
        }

        toneMemo = sequence;

        timingCursor += baseTiming;
        const playStart = playStarter(timingCursor, index + 1);

        timingCursor += playTiming * (sequence.length + 1);
        timingCursor += slowPlayerBufferTiming;

        const roundEnd = roundEnder(timingCursor, index + 1);

        return [...timings, roundStart, ...sequence, playStart, roundEnd];
      },
      [...gameStarter(0, 0)]
    ).concat([gameEnder(timingCursor + 100)])
};







function gameStarter(time) {
  return [GREEN, RED, BLUE, null].map((tone, index) => ({
    Flash: tone,
    round: 0,
    time: time + index * 1000,
    Playing: false,
    Watching: false,
    Countdown: 4 - index,
  }));
}

function roundStarter(time, round) {
  return {
    Playing: false,
    Watching: true,
    round,
    time,
    debug: "=====================ROUND STARTED==========================",
  };
}

function roundEnder(time, round) {
  return {
    Playing: false,
    Watching: true,
    time,
    round,
    debug: "=====================ROUND ENDED==========================",
  };
}

function countdownStarter(time, round) {
  return [YELLOW, GREEN, RED, BLUE, null].map((tone, index) => ({
    Playing: false,
    Watching: false,
    Flash: tone,
    Countdown: 5 - index,
    time: time + index * 1000,
    round,
    debug: `=====================ROUND ${round} in ${
      5 - index
      }==========================`,
  }));
}

function playStarter(time, round) {
  return {
    Playing: true,
    Watching: false,
    time,
    round,
    debug: "=====================PLAY STARTED==========================",
  };
}

function gameEnder(time) {
  return {
    GameOver: true,
    time,
    Playing: false,
    Watching: true,
    debug: "=====================GAME OVER==========================",
  };
}

export { LOBBY_TIMINGS, ROUND_LOAD_SEQUENCE, randomTimings, randomSequence, randomTimingsNoEnd };
