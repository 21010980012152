import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import SimonLogo from "~/components/simonLogo";
import SimonHUD from "~/components/simonHud";
import SimonEsb from "~/components/simonEsb";
import SimonScore from "~/components/simonScore";
import SimonLeaderInput from "~/components/simonLeaderInput";
import ResponsiveContainer from "~/components/responsiveContainer";
import SocialButtons from "~/components/socialButtons";
import ButtonLinkRow from "~/components/simonButtonLinkRow";
import { maxScore } from "~/utils/score";
import { useRank } from "~/components/syncComponent/syncAPI";
import NumberOfPlayersContext from "~/utils/numPlayers";
import Button from "../../components/button";
import {postUserEmail} from "../../utils/syncClient"


const EmailInput = ({SetUser}) =>{
  const [email, setEmail] = useState('')

  return(
    <div>
      <input type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}></input>
      <Button onClick={()=>SetUser(email)}>Submit</Button>
    </div>
  )
}



export default () => {
  const { state = { score: 0, practice: false, infinite:false, rounds: 10 } } = useLocation();
  const { score, practice, rounds, infinite } = state;
  const [userEmail, setUserEmail] = useState(window.localStorage.getItem('user'))
  const [postSent, setPostSent] = useState(false)
  const rank = useRank();
  const leader = rank && rank <= 10;
  const showEmailInput = userEmail == null
  const calculating = !practice && !rank && !infinite;
  const numPlayers = useContext(NumberOfPlayersContext);

  if(userEmail !== null && !postSent && rank){
    postUserEmail(userEmail, rank)
    setPostSent(true)
  }

  const setUser = (email) =>{
    window.localStorage.setItem('user', email) 
    postUserEmail(email, rank)
    setUserEmail(email)
    setPostSent(true)
  }

  const subtitle = () => {
    if (practice)
      return `You climbed ${score} out of a possible ${maxScore(rounds)} feet`;
    if (showEmailInput) return "Enter your email to see how you ranked."
    if (infinite) return `You climbed ${score} feet!`;
    if (calculating) return "Give us a minute to calculate the results...";
    return `You ranked ${rank} out of ${numPlayers} players`;
  };

  const title = () => {
    if (calculating) return "Well Done!";
    if (leader) return "Great Game!";
    return "Nice Game!";
  };

  const renderButtons = () => {
    if (calculating) return false;
    if (leader) return false;
    if (practice) return true;
    if (infinite) return true;
    return false;
  };



  return (
    <div className={styles.container}>
      <ResponsiveContainer>
        <SimonLogo />
        {!showEmailInput && <SimonScore score={score} />}
        <SimonEsb active={null} sequence={[]} playing={false} />
      </ResponsiveContainer>
      <SimonHUD title={title()} subtitle={subtitle()}>
        {showEmailInput && !practice && <EmailInput SetUser={setUser} />}
        {!showEmailInput && leader && <SimonLeaderInput rank={rank} />}
        {!showEmailInput && infinite && <SimonLeaderInput infinite={infinite} score={score} email={userEmail} />}
        {renderButtons() && (infinite?(
          <>
          <SocialButtons hashtag />
          <ButtonLinkRow
            links={[
              {
                to: "/leaderboard",
                text: "Leaderboard",
                buttonProps: {size: "small"}
              },
              {
                to: "/lobby",
                text:"Home",
                buttonProps: {size: "small"}
              },
              {
                to: "/infinite",
                text:"Play Again",
                buttonProps: {size: "small"}
              },
            ]}
          />
        </>
        ):(
          <>
          <SocialButtons hashtag />
          <ButtonLinkRow
            links={[
              {
                to: "/leaderboard",
                text: "Leaderboard",
              },
              {
                to: "/lobby",
                text:"Home",
              },
            ]}
          />
        </>
        )
        )}
      </SimonHUD>
    </div>
  );
};
