import { useEffect } from "react";

const fetchSyncCore = async (syncTag) => {
  const response = await fetch(process.env.REACT_APP_SYNC_ROUTER_URL + syncTag);
  if (!response.ok) {
    // handle errors somehow
  }
  return response.json();
};

export const postUserEmail = async (email, rank)=>{
  var data = {email: email, rank: rank}
  const response = await fetch('https://5bd4oohoak.execute-api.us-east-1.amazonaws.com/create-email', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return response
}

export default fetchSyncCore;


export const postUserScore = async (email, score, initials)=>{
  var data = {email: email, score: score, initials: initials}
  const response = await fetch('https://5bd4oohoak.execute-api.us-east-1.amazonaws.com/scoreboard', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return response
}

export const getScoreBoard = async ()=>{
  const response = await fetch('https://5bd4oohoak.execute-api.us-east-1.amazonaws.com/scoreboard', {
    method: 'GET'
  })
  return response.json()
}

export const postRating = async (data)=>{
  const response = await fetch('https://5bd4oohoak.execute-api.us-east-1.amazonaws.com/rating', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return response.json()
}