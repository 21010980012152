// import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { addHours, startOfDay } from "date-fns";
import styles from "./styles.module.css";
import SimonLogo from "~/components/simonLogo";
import SimonHUD from "~/components/simonHud";
import SimonEsb from "~/components/simonEsb";
import zIndices from "~/utils/zIndices";
import ResponsiveContainer from "~/components/responsiveContainer";
import SocialButtons from "~/components/socialButtons";
import ButtonLinkRow from "~/components/simonButtonLinkRow";
import { customTimeFormat } from "~/utils";
import { useLeaderboard } from "~/components/syncComponent/syncAPI";
import { getScoreBoard } from "../../utils/syncClient";
import { stripLeadingSlash } from "history/PathUtils";
import { Rating } from "../../components/rating";

export default () => {
  const { state = { score: 0, practice: false, infinite:true, rounds: 10 } } = useLocation();
  const { score, practice, rounds, infinite } = state;
  var leaderboard
  if(infinite){
    console.log('is infinite')
   var [leaderboard, setLeaderboard] = useState([])
  } else{
    console.log('is live')
    var [leaderboard] = useLeaderboard();
  }

  const nextGame = addHours(startOfDay(new Date()), 46);


  useEffect(()=>{
    if(infinite){
    getScoreBoard().then((data)=>{
      setLeaderboard(data.Items)
    })
      getScoreBoard().then((data)=>{
        setLeaderboard(data.Items)
      })
      setInterval(() => {
        getScoreBoard().then((data)=>{
          setLeaderboard(data.Items)
        })
      }, 3000);
    }
  }, [])



  const subtitle = (
    <div>
    <span>Try again to secure your spot on the leaderboard!</span>
    </div>
  );
  return (
    <div className={styles.container}>
      <ResponsiveContainer>
        <SimonLogo />
        <div className={styles.leaderboard} style={{ zIndex: zIndices.BRAND }}>
          <h3>Top {leaderboard.length} {infinite? "Global":""} Players</h3>
          <div className={styles.scrollingContainer}>
          <table>
            <thead>
              <tr>
                <td>Player</td>
                <td>Height</td>
              </tr>
            </thead>
            <tbody>
            
              {leaderboard.map((leader, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  <td>
                    <span className={styles.rank}>{index+1}.</span>
                    {leader.initials || leader.Initials}
                  </td>
                  <td>{leader?.score?.toLocaleString() || leader.Score || 0} feet</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          </div>

      </ResponsiveContainer>
      <SimonHUD subtitle={subtitle}>
      <Rating title="How do you like Simon?"></Rating>
        <SocialButtons hashtag />
        <ButtonLinkRow
          links={[
            {
              to: {
                pathname: "/lobby",
                state: { skipIntro: true },
              },
              text: "Home",
            },
          ]}
        />
      </SimonHUD>
    </div>
  );
};
