const SCORE_PER_TAP = 10;
const ROUND_BONUS_BASE = 5;
const roundBonus = (round) => round * ROUND_BONUS_BASE;

const maxScore = (rounds) =>
  Array.from({ length: rounds }, (_, i) => i + 1).reduce(
    (score, round) => score + (round + 2) * SCORE_PER_TAP,
    0
  );

export { SCORE_PER_TAP, ROUND_BONUS_BASE, roundBonus, maxScore };
