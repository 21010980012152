import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Donut from "./donut";
import zIndices from "~/utils/zIndices";
import styles from "./styles.module.css";

const SimonLoader = ({
  active,
  allActive,
  copy,
  copyAt,
  countdown,
  fadeCoverAt,
  fadeDonutAt,
  showCountdown,
}) => {
  const [fadeDonut, setFadeDonut] = useState(false);
  const [fadeCover, setFadeCover] = useState(false);

  useEffect(() => {
    if (countdown <= 0) return;

    if (countdown <= fadeDonutAt) {
      setFadeDonut(true);
    }

    if (countdown <= fadeCoverAt) {
      setFadeCover(true);
    }
  }, [countdown]);

  return (
    <div
      className={classnames(styles.root, {
        [styles.fadeDonut]: fadeDonut,
        [styles.fadeCover]: fadeCover,
      })}
      style={{ zIndex: zIndices.GAME_INTERSTITIAL }}
    >
      <Donut
        allActive={allActive}
        active={active}
        width="100"
        className={styles.donut}
      />
      {showCountdown && <span className={styles.counter}>{countdown}</span>}
      <span
        className={classnames(styles.copy, {
          [styles.showCopy]: countdown <= copyAt,
        })}
      >
        {copy}
      </span>
    </div>
  );
};

SimonLoader.propTypes = {
  active: PropTypes.number,
  allActive: PropTypes.bool.isRequired,
  copy: PropTypes.string,
  copyAt: PropTypes.number,
  countdown: PropTypes.number,
  fadeCoverAt: PropTypes.number,
  fadeDonutAt: PropTypes.number,
  showCountdown: PropTypes.bool,
};

SimonLoader.defaultProps = {
  active: null,
  copy: null,
  copyAt: null,
  countdown: null,
  fadeCoverAt: 2,
  fadeDonutAt: 4,
  showCountdown: true,
};

// eslint-disable-next-line react/prop-types
const CountdownLoader = ({ countdown: countdownProp, ...props }) => {
  const [countdown, setCounter] = useState(6);

  useEffect(() => {
    if (countdownProp || countdown <= 0) return;
    const timeout = setTimeout(() => setCounter(countdown - 1), 1000);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [countdown]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SimonLoader {...props} countdown={countdownProp || countdown} />;
};

export default CountdownLoader;
