import React from "react";
import { Link } from "react-router-dom";
import Button from "~/components/button";
import styles from "./styles.module.css";

import Hasbro from "~/resources/hasbro.svg";
import Esb from "~/resources/esblogo-reversed.svg";
import Tactman from "~/resources/tactman.svg";

export default () => (
  <div className={styles.main}>
    <img alt="Hasbro logo" src={Hasbro} width="100" />
    <img alt="Empire State Building logo" src={Esb} width="192" />
    <img alt="TactMan logo" src={Tactman} width="146" />
    <Link to="/howto" replace>
      <Button variant="secondary" size="large">
        Let&apos;s Play!
      </Button>
    </Link>
    <div className={styles.legal}>By clicking above to play, you're agreeing to our <a href="https://www.esbnyc.com/terms-conditions">terms of service</a> and <a href="https://www.esbnyc.com/privacy-policy">privacy policy.</a></div>
    <div className={styles.legal}>The Empire State Building image® is a registered trademark of ESRT Empire State Building, L.L.C. and is used with permission.</div>
  </div>
);
