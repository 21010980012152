import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Button from "~/components/button";

import styles from "./styles.module.css";

const ButtonLinkRow = ({ links }) => (
  <div className={styles.buttonRow}>
    {links.map((link) => {
      const button = (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Button key={link.to} disabled={link.disabled} {...link.buttonProps}>
          {link.text}
        </Button>
      );

      if (link.disabled) return button;

      return (
        <Link key={link.to} to={link.to}>
          {button}
        </Link>
      );
    })}
  </div>
);

ButtonLinkRow.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      buttonProps: PropTypes.shape({
        size: PropTypes.string,
        variant: PropTypes.string,
      }),
      to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          pathname: PropTypes.string.isRequired,
          state: PropTypes.object.isRequired,
        }),
      ]),
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

ButtonLinkRow.defaultProps = {
  buttonProps: {
    size: undefined,
    variant: undefined,
  },
};

export default ButtonLinkRow;
