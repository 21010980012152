import { isToday, isTomorrow } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";

export default (date) => {
  const time = formatToTimeZone(date.toDate(), "@ h:mma z", {
    timeZone: "America/New_York",
  });
  switch (true) {
    case isToday(date.toDate()):
      return `Today ${time}`;
    case isTomorrow(date.toDate()):
      return `Tomorrow ${time}`;
    default:
      return formatToTimeZone(date, "dddd @ h:mma z", {
        timeZone: "America/New_York",
      });
  }
};
