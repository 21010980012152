import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "~/components/button";

import styles from "./styles.module.css";
import { claimRank } from "~/components/syncComponent/syncAPI";
import {postUserScore} from '~/utils/syncClient'

const SimonLeaderInput = ({ infinite, rank, score, email }) => {
  const history = useHistory();
  const [initials, _setInitials] = useState("");
  const setInitials = (value) => {
    if (value.length <= 3) {
      _setInitials(value);
    }
  };
  const input = useRef();
  const [focused, setFocused] = useState(false);

  const setFocus = () => {
    setFocused(true);
    input.current.focus();
    const { length } = initials;
    input.current.setSelectionRange(length, length);
    window.scroll({
      top: document.body.scrollHeight * 0.3,
      behavior: "smooth",
    });
  };

  const submitInitials = (e) => {
    e.preventDefault();
    if (!infinite) claimRank(initials, rank);
    if (infinite) postUserScore(email, score, initials )
    history.replace({pathname:"/leaderboard", state: { infinite }});
  };

  useEffect(() => setFocus(), []);

  return (
    <div className={styles.root}>
      <form onSubmit={submitInitials}>
        <label className={styles.label} htmlFor="input">
          Enter your initials for the leaderboard:
          <div role="textbox" tabIndex={0} className={styles.initials}>
            <div>
              {initials[0]}
              {focused && initials.length === 0 && (
                <span className={styles.cursor} />
              )}
            </div>
            <div>
              {initials[1]}
              {initials.length === 1 && <span className={styles.cursor} />}
            </div>
            <div>
              {initials[2]}
              {initials.length === 2 && <span className={styles.cursor} />}
            </div>
          </div>
          <input
            id="input"
            ref={input}
            value={initials}
            onChange={(e) => setInitials(e.target.value)}
            onFocus={setFocus}
            onBlur={() => {
              setFocused(false);
              window.scroll({
                top: 0,
                behavior: "smooth",
              });
            }}
            className={styles.hiddenInput}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </label>

        <Button
          disabled={initials.length !== 3}
          type="submit"
          className={styles.button}
          variant="secondary"
        >
          OK
        </Button>
      </form>
    </div>
  );
};


SimonLeaderInput.defaultProps = {};

export default SimonLeaderInput;
