import React, { useState } from "react";
import ResponsiveContainer from "~/components/responsiveContainer";
import SimonPill from "~/components/simonPill";
import SimonLogo from "~/components/simonLogo";
import SimonEsb from "~/components/simonEsb";
import SimonScore from "~/components/simonScore";

const SimonBoardNew = () => {
  const [playing, setPlaying] = useState(true);
  return (
    <ResponsiveContainer>
      <SimonLogo />
      <SimonScore score={100} />
      <SimonEsb
        active={null}
        playing={playing}
        onWon={() => setPlaying(false)}
        onLost={() => setPlaying(false)}
        sequence={[{ Flash: 1 }, { Flash: 1 }, { Flash: 1 }, { Flash: 1 }]}
      />

      <SimonPill
        numPlayers={130000}
        round={1}
        roundInfo="This is a test board"
      />
    </ResponsiveContainer>
  );
};

export default SimonBoardNew;
